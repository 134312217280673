.App {
  background: #2980b9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffffff,
    #ffffff,
    #6dd5fa
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff,
    #ffffff,
    #6dd5fa
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow-y: hidden;
}

.main {
  min-height: calc(100vh - 400px);
}

div[data-amplify-authenticator][data-variation="modal"] {
  background-color: transparent;
}

div[data-amplify-authenticator][data-variation="modal"]::before {
  background-image: none;
}

.title {
  text-align: center;
  border: 0 !important;
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 250% !important;
  margin-bottom: 1rem;
  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  font-weight: 500;
}

.login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: var(--dark);
  z-index: 3;
}

.logo {
  font-size: 1.5rem;
  display: flex;
  color: var(--primary);
  align-items: center;
}

.logo svg {
  height: 50px;
}

.pin {
  min-width: 320px;
}

.card-wrap {
  min-height: 100px;
}

.card-inner {
  width: 275px;
}

.card-price {
  color: var(--red);
  font-weight: 500;
}

.pie {
  width: 500px;
  height: 300px;
}

.pie rect {
  fill: transparent;
}

.sub-badge {
  background-color: var(--success);
  color: var(--light);
  float: right;
}

.spinner {
  border-width: 0.5em;
  width: 100px;
  height: 100px;
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

footer {
  height: 400px;
}

.globe {
  display: block;
  width: 70%;
  max-width: 1000px;
  margin: 0 auto;
}

.rotate {
  animation: rotation 60s infinite linear;
}

.place.card-group {
  flex-flow: unset !important;
}

.created {
  position: absolute;
  bottom: 0;
  text-align: center;
  color: var(--black);
  z-index: 10;
}

.created a {
  color: var(--black);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1400px) {
  .main {
    min-height: calc(100vh - 500px);
  }

  footer {
    height: 500px;
  }
}

@media screen and (max-width: 800px) {
  .main {
    min-height: 100vh;
  }
  footer {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    margin: 10px 0;
    font-size: 24px;
  }

  div[data-amplify-authenticator] {
    margin-top: 10rem;
  }

  div[data-amplify-authenticator][data-variation="modal"] {
    padding-top: 0;
  }

  .logo svg {
    height: 30px;
  }

  .login {
    right: 10px;
    top: 5px;
  }

  .login strong,
  footer {
    display: none !important;
  }

  .pie {
    width: 400px !important;
    margin: 20px 0 0 -40px;
    overflow: hidden;
    width: auto;
    height: auto;
  }
}
